<template>
  <q-page class="column justify-center q-text-center">
    <q-img src="/img/logo.png" fit />
    <q-card square bordered class="q-mt-md q-pa-sm shadow-1">
      <q-card-section style="width: 320px">
        <q-form>
          <q-input
            ref="email"
            square
            filled
            clearable
            v-model="email"
            :label="$t('label.username')"
            lazy-rules
            :rules="[this.required]"
          >
            <template v-slot:prepend> <q-icon name="email" /> </template>
          </q-input>
          <q-input
            ref="password"
            square
            filled
            clearable
            v-model="password"
            :type="passwordFieldType"
            :label="$t('label.password')"
            lazy-rules
            :rules="[this.required]"
            @keydown.enter="login"
          >
            <template v-slot:prepend>
              <q-icon name="lock" />
            </template>
            <template v-slot:append>
              <q-icon
                :name="visibilityIcon"
                @click="switchVisibility"
                class="cursor-pointer"
              />
            </template>
          </q-input>
        </q-form>
        <div v-if="errorMessage" class="text-red-vito text-center">
          <strong>{{ errorMessage }}</strong>
        </div>
      </q-card-section>
      <q-card-actions class="q-px-md">
        <q-btn
          unelevated
          size="md"
          class="full-width bg-blue-btz text-white"
          :label="$t('button.signIn')"
          @click="login"
        />
      </q-card-actions>
    </q-card>
  </q-page>
</template>

<script>
import AuthApi from "../apis/Auth";
import { useI18n } from "vue-i18n";
import moment from "moment";

export default {
  setup() {
    const { locale } = useI18n({ useScope: "global" });
    return {
      locale,
    };
  },
  data: () => {
    return {
      password: "",
      email: "",
      passwordFieldType: "password",
      visibilityIcon: "visibility",
      errorMessage: null,
    };
  },
  mounted() {
    this.locale = localStorage.getItem("language");
    moment.locale(this.locale);
  },
  methods: {
    async login() {
      this.errorMessage = null;
      ["email", "password"].forEach((f) => {
        if (!this.$refs[f].validate()) {
          this.errorMessage = this.$t("validation.verify");
          return;
        }
      });
      if (!this.errorMessage) {
        try {
          const loginResponse = await AuthApi.login({
            username: this.email,
            password: this.password,
          });
          localStorage.setItem("token", loginResponse.data.access_token);
          this.$store.state.access_token = loginResponse.data.access_token;
          const profileResponse = await AuthApi.profile();
          this.$store.commit("setUser", profileResponse.data);
          localStorage.setItem("language", profileResponse.data.language);

          this.$router.push(
            this.$route.query.redirect
              ? this.$route.query.redirect == "/login"
                ? "/"
                : this.$route.query.redirect
              : "/"
          );
        } catch (error) {
          this.errorMessage = this.$t(error.response.data.message);
          //console.log(error.response.data.message);
        }
      }
    },
    required(val) {
      return (val && val.length > 0) || this.$t("validation.required");
    },
    switchVisibility() {
      this.visibility = !this.visibility;
      this.passwordFieldType = this.visibility ? "text" : "password";
      this.visibilityIcon = this.visibility ? "visibility_off" : "visibility";
    },
  },
};
</script>
