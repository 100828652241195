<template>
  <q-card
    flat
    class="q-pa-sm full-width no-padding-md"
    style="max-width: 100vw"
  >
    <q-card-section class="row q-pb-xs text-green-vito justify-between">
      <div class="text-h5">
        {{ $t("title.executions") }}
      </div>
      <div class="q-gutter-x-md row">
        <q-input
          outlined
          dense
          clearable
          debounce="300"
          v-model="filter"
          style="width: 150px"
          :placeholder="$t('label.search')"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </div>
    </q-card-section>

    <q-card-section>
      <q-table
        :rows="executions"
        :columns="columnsI18n"
        row-key="id"
        :loading="loading"
        :filter="filter"
        binary-state-sort
        class="sticky-header-table"
      >
        <template v-slot:body-cell-actions="props">
          <q-td :props="props">
            <div class="q-gutter-x-sm">
              <q-btn
                icon="visibility"
                square
                round
                dense
                @click="viewLog(props.row)"
              >
                <q-tooltip class="bg-blue-btz text-no-wrap">
                  {{ $t("tooltip.viewLog") }}
                </q-tooltip>
              </q-btn>
            </div>
          </q-td>
        </template>
      </q-table>
    </q-card-section>
  </q-card>
  <q-dialog v-model="showLogCard" ref="ShowLogMessage">
    <q-card
      bordered
      class="q-mt-md q-pa-md shadow-1"
      style="width: 750px; max-width: 90vw"
    >
      <q-card-section class="col-12 col-sm-auto">
        <span v-html="nl2br(log, true, true)"></span>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>
<script>
import ScriptApi from "../apis/Script";
import { useI18n } from "vue-i18n";
import { date } from "quasar";

export default {
  name: "ExecutionsPage",

  setup() {
    const { locale } = useI18n({ useScope: "global" });
    return {
      locale,
    };
  },
  data: () => {
    return {
      action: "list",
      showNew: false,
      showCard: false,
      filter: "",
      loading: false,
      executions: [],
      perPage: 50,
      showLogCard: false,
      log: "",
      pagination: {
        sortBy: "id",
        descending: false,
        page: 1,
        rowsPerPage: 50,
      },
    };
  },
  methods: {
    async getExecutions() {
      this.loading = true;
      try {
        const executionsReponse = await ScriptApi.findExecutions(
          this.$route.params.scriptId
        );

        this.executions = executionsReponse.data;
        this.loading = false;
        //
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    async refresh() {
      this.showNew = false;
      this.showCard = false;
      await this.getExecutions();
    },
    async viewLog(exec) {
      this.showLogCard = true;
      this.log = exec.log;
    },
    nl2br(str, replaceMode, isXhtml) {
      var breakTag = isXhtml ? "<br />" : "<br>";
      var replaceStr = replaceMode ? "$1" + breakTag : "$1" + breakTag + "$2";
      return (str + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
    },
  },

  async mounted() {
    this.$store.state.title = "button.scripts";
    await this.getExecutions();
  },

  computed: {
    columnsI18n() {
      const columns = [
        {
          name: "date",
          label: this.$t("label.date"),
          field: "executionDate",
          format: (val) => date.formatDate(new Date(val), "DD-MM-YYYY HH:mm"),
          align: "left",
          sortable: true,
        },
        {
          name: "duration",
          label: this.$t("label.duration"),
          format: (val) => val / 1000,
          field: "duration",
          align: "left",
          sortable: true,
        },
        {
          name: "result",
          label: this.$t("label.result"),
          field: "result",
          align: "left",
          sortable: true,
        },
        { name: "actions", style: "width:70px" },
      ];
      return columns;
    },
  },
};
</script>
