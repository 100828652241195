import { createRouter, createWebHistory } from "vue-router";
import ClearLayout from "../layouts/Clear.vue";
import FullLayout from "../layouts/Full.vue";
import Login from "../pages/Login.vue";
import NotFound404 from "../components/NotFound404.vue";
import Users from "../pages/Users.vue";
import Scripts from "../pages/Scripts.vue";
import ScriptExecutions from "../pages/ScriptExecutions.vue";
import ReportRefill from "../pages/ReportRefill.vue";
import ReportDefects from "../pages/ReportDefects.vue";
import ReportChanges from "../pages/ReportChanges.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: FullLayout,
    meta: { requireAuth: true },
    children: [
      { path: "/", redirect: "/scripts" },
      { path: "users", component: Users },
      { path: "scripts", component: Scripts },
      { path: "executions/:scriptId", component: ScriptExecutions },
      { path: "report/refill", component: ReportRefill },
      { path: "report/defects", component: ReportDefects },
      { path: "report/changes", component: ReportChanges },
    ],
  },
  {
    path: "/login",
    component: ClearLayout,
    children: [
      { path: "", component: Login },
      { path: "signin", component: Login },
      { path: "/:pathMatch(.*)*", name: "not-found", component: NotFound404 },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.FRONT_BASE_URL),
  routes,
});

export default router;
