import Api from "./Api";
export default {
  getRefillReport(start, end) {
    return Api().get(`/refill/report?from=${start}&to=${end}`);
  },
  getRefillDetailReport(reportId) {
    return Api().get(`/refill/report/${reportId}`);
  },
  getDefects() {
    return Api().get(`/store/defects`);
  },
  getChangesReport(start, end) {
    return Api().get(`/log/changes?from=${start}&to=${end}`);
  },
  getLogsDetailReport(start, end, store) {
    return Api().get(`/log?from=${start}&to=${end}&store=${store}`);
  },
};
