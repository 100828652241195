<template>
  <q-card
    flat
    class="q-pa-sm full-width no-padding-md"
    style="max-width: 100vw"
  >
    <q-card-section class="row q-pb-xs text-green-vito justify-between">
      <div class="text-h5">
        {{ $t("title.defects") }}
      </div>
      <div class="q-gutter-x-md row items-center">
        <q-input
          outlined
          dense
          clearable
          debounce="300"
          v-model="filter"
          style="width: 150px"
          :placeholder="$t('label.search')"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
        <download-excel :data="report" :fields="export_fields">
          Export <br />excel
        </download-excel>
      </div>
    </q-card-section>

    <q-card-section>
      <q-table
        :rows="report"
        :columns="columnsI18n"
        row-key="id"
        :pagination="pagination"
        :loading="loading"
        :filter="filter"
        class="sticky-header-table"
        :visible-columns="visibleColumns"
      >
        <template v-slot:body-cell-actions="props">
          <q-td :props="props">
            <div class="q-gutter-x-sm">
              <q-btn
                icon="visibility"
                square
                round
                dense
                @click="viewDetail(props.key)"
              >
                <q-tooltip class="bg-blue-btz text-no-wrap">
                  {{ $t("tooltip.viewScript") }}
                </q-tooltip>
              </q-btn>
            </div>
          </q-td>
        </template>
      </q-table>
    </q-card-section>
  </q-card>
</template>
<script>
import ReportApi from "../apis/Report";

import { useI18n } from "vue-i18n";

export default {
  name: "ScriptsPage",

  components: {},
  setup() {
    const { locale } = useI18n({ useScope: "global" });
    return {
      locale,
    };
  },
  data: () => {
    return {
      action: "list",
      showNew: false,
      showDetail: false,
      filter: "",
      loading: false,
      export_fields: [],
      report: [],
      perPage: 50,
      pagination: {
        sortBy: "defects",
        descending: true,
        page: 1,
        rowsPerPage: 50,
      },
    };
  },
  watch: {
    fromDate: function () {
      this.getReport();
    },
    toDate: function () {
      this.getReport();
    },
  },
  methods: {
    async getReport() {
      this.loading = true;
      try {
        const reportReponse = await ReportApi.getDefects();

        this.report = reportReponse.data;
        this.loading = false;
        //
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    async viewDetail(reportId) {
      this.showDetail = true;
      this.selectedReportId = reportId;
    },
    async refresh() {
      this.showNew = false;
      this.showDetail = false;
      await this.getReport();
    },
    get_excel_fields() {
      return {
        Id: "id",
        PDV: {
          callback: (row) => row.code,
        },
        Ville: {
          callback: (row) => row.city,
        },
        Nom: {
          callback: (row) => row.name,
        },
        Mandataire: {
          callback: (row) => row.agent,
        },
        Defects: "defects",
        Total: "total",
      };
    },
  },

  async mounted() {
    this.$store.state.title = "button.defects";
    await this.getReport();
    this.export_fields = this.get_excel_fields();
  },

  computed: {
    columnsI18n() {
      const columns = [
        {
          name: "id",
          required: true,
          label: this.$t("label.id"),
          align: "left",
          field: "id",
        },
        {
          name: "code",
          required: true,
          label: this.$t("label.code"),
          align: "left",
          field: (row) => row.code,
          sortable: true,
        },
        {
          name: "city",
          required: true,
          label: this.$t("label.city"),
          field: (row) => row.city,
          align: "left",
          sortable: true,
        },
        {
          name: "name",
          required: true,
          label: this.$t("label.name"),
          field: (row) => row.name,
          align: "left",
          sortable: true,
        },
        {
          name: "agent",
          required: true,
          label: this.$t("label.agent"),
          field: (row) => row.agent,
          align: "left",
          sortable: true,
        },
        {
          name: "defects",
          label: this.$t("label.defects"),
          field: (row) => +row.defects,
          align: "left",
          sortable: true,
        },
        {
          name: "total",
          label: this.$t("label.total"),
          field: "total",
          align: "left",
          sortable: true,
        },
      ];
      return columns;
    },
  },
};
</script>
